
.change-password {
  background: #ffffff;
  box-shadow: 0px 2px 34px rgba(46, 48, 92, 0.11);
  padding: 40px 32px;
  .header {
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    color: #000000;
  }
  .form {
    padding: 0px;
    margin-top: 30px;
    .custom-input:not(:nth-child(3)) {
      margin-bottom: 16px;
    }
    .submit-btn {
      cursor: pointer;
      display: block;
      margin-top: 32px;
      background: #fa8c28;
      border-radius: 4px;
      padding: 23px 24px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-transform: uppercase;
      text-align: center;
      color: #ffffff;
      width: 100%;
      &.disabled {
        background-color: #eeeeee;
        cursor: not-allowed;
        pointer-events: none;
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 40px 24px;
    .form {
      padding: 37px 0 0;
    }
  }
}
